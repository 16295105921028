<template>
  <v-container
    pa-0
    fill-height
    fluid
    video-test
    class="landing__video-container"
  >
    <a class="landing__link" href="https://thegogame.com">
      <Media
        class="landing__video-background"
        :kind="'video'"
        :src="require('../assets/landing-video.mp4')"
        :poster="require('../assets/landing-video-poster.jpg')"
        :autoplay="true"
        :controls="false"
        :muted="true"
        :playsInline="true"
      ></Media>
      <Media
        class="landing__video"
        :kind="'video'"
        :src="require('../assets/landing-video.mp4')"
        :poster="require('../assets/landing-video-poster.jpg')"
        :autoplay="true"
        :controls="false"
        :muted="true"
        :playsInline="true"
      ></Media>
    </a>
  </v-container>
</template>

<script>
import Media from "@/components/Media"

export default {
  name: "Landing",
  components: { Media },
  data() {
    return {}
  }
}
</script>
<style lang="scss">
.landing__video-container {
  position: relative;
  overflow: hidden;
  background-color: black;
}
.landing__video {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.landing__video-background {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0.6;
  filter: blur(20px);
}
</style>
