var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "landing__video-container",
      attrs: { "pa-0": "", "fill-height": "", fluid: "", "video-test": "" }
    },
    [
      _c(
        "a",
        {
          staticClass: "landing__link",
          attrs: { href: "https://thegogame.com" }
        },
        [
          _c("Media", {
            staticClass: "landing__video-background",
            attrs: {
              kind: "video",
              src: require("../assets/landing-video.mp4"),
              poster: require("../assets/landing-video-poster.jpg"),
              autoplay: true,
              controls: false,
              muted: true,
              playsInline: true
            }
          }),
          _c("Media", {
            staticClass: "landing__video",
            attrs: {
              kind: "video",
              src: require("../assets/landing-video.mp4"),
              poster: require("../assets/landing-video-poster.jpg"),
              autoplay: true,
              controls: false,
              muted: true,
              playsInline: true
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }